module.exports = {
    firebase: {
        apiKey: "AIzaSyBmhWFqVfsRBu-Lnlt_b8ZguwcO2pYIKmc",
        authDomain: "parkable-dev.firebaseapp.com",
        appId: "1:1068172338961:web:798669bc2f595705de4eb0",
        databaseURL: "https://parkable-dev.firebaseio.com",
        messagingSenderId: "1068172338961",
        projectId: "parkable-dev",
        storageBucket: "api-dev.parkable.com",
    },
    showBuildNumber: true,
    version: "9.18.0",
    build: 880090,
};
